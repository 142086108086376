import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import InfoPage from "../components/InfoPage/InfoPage";

export default function PrivacyPolicy(){
    const data = useStaticQuery(graphql`
    query{
        allWpPage(filter: {title: {eq: "Terms of Service"}}) {
    nodes {
      content
      title
      template {
        seoMetaTags {
            seoMetaTagsTitle
            seoMetaTagsDescription
            seoMetaTagsJsonShema
        }
      }
    }
  }
    }
    `)
    const content = data.allWpPage.nodes[0].content;
    const title = data.allWpPage.nodes[0].title;
    const seoData = data.allWpPage.nodes[0].template.seoMetaTags;
    const seo = {
        title: seoData?.seoMetaTagsTitle,
        description: seoData?.seoMetaTagsDescription
    }

    return(
    <>
    {content ? 
        <InfoPage seo={seo} title={title} content={content} />
        : 
        <p>No Info</p>
    }
    </>
    )
}